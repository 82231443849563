









































































































.tcell
  display: flex;
  align-items: center;
  justify-content: flex-start;

.avatar
  margin-right 15px

.individual-menu
  position: absolute;
  top: 0px;
  right: 12px;

.actions
  height 50px

.individual
  position relative
  @media (min-width 1024px)
    width 75%

.small-individual-list
  .individual
    width 96%
  .small-hide
    display none
  .individual
    max-height 70px
