.container {
  display: inline-block;
}
.labelled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.labelled .q-btn {
  display: block;
  width: 182px;
}
.button {
  margin-top: 10px;
}
/*# sourceMappingURL=src/pages/individuals/individual-meta-actions.css.map */