.tcell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.avatar {
  margin-right: 15px;
}
.individual-menu {
  position: absolute;
  top: 0px;
  right: 12px;
}
.actions {
  height: 50px;
}
.individual {
  position: relative;
}
@media (min-width: 1024px) {
  .individual {
    width: 75%;
  }
}
.small-individual-list .individual {
  width: 96%;
}
.small-individual-list .small-hide {
  display: none;
}
.small-individual-list .individual {
  max-height: 70px;
}
/*# sourceMappingURL=src/pages/individuals/list/individual-card.css.map */