




















































































































































.index-list
  height calc(100vh - 185px)
  @media (min-width 768px)
    padding 0 3rem
    height calc(100vh - 271px)

  .row-item:first-child
    margin-top 16px

.index-head, .resource-card
  @media (min-width 768px)
    width 75%

.resource-card
  max-height 82px
  position relative

.row-item
  width 100%

.tools
  @media (min-width: 768px)
    width 50%

.search
  margin-bottom 10px
  @media (min-width: 768px)
    margin-bottom 0px

